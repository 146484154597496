import Link from "next/link";
import Image from "next/image";
import Navigation from "./navigation";

export default function Header() {
  return (
    <header
      data-elementor-type="header"
      data-elementor-id="17"
      className="elementor elementor-17 elementor-location-header"
    >
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-e124f36 elementor-section-height-min-height elementor-section-items-stretch elementor-section-full_width elementor-section-content-middle elementor-section-height-default elementor-sticky--effects elementor-sticky elementor-sticky--active elementor-section--handles-inside"
        data-id="e124f36"
        data-element_type="section"
        data-settings='{"background_background":"classic","sticky":"top","sticky_on":["desktop","tablet","mobile"],"sticky_offset":0,"sticky_effects_offset":0}'
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-4eab1c0"
            data-id="4eab1c0"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-e1e7774 elementor-widget elementor-widget-theme-site-logo elementor-widget-image"
                data-id="e1e7774"
                data-element_type="widget"
                data-widget_type="theme-site-logo.default"
              >
                <div className="elementor-widget-container">
                  <Link href="/" title="IS Auditr">
                    <Image
                      width={225}
                      height={125}
                      loading="lazy"
                      alt="IS Auditr Logo"
                      src="/images/logo.svg"
                      className="attachment-large size-large"
                      // sizes="100vw"
                      style={{
                        width: "60%",
                        height: "auto",
                      }}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-a80e820"
            data-id="a80e820"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-7aff5c2 elementor-nav-menu__align-center elementor-nav-menu--stretch elementor-nav-menu--dropdown-mobile elementor-nav-menu__text-align-aside elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
                data-id="7aff5c2"
                data-element_type="widget"
                data-settings='{"full_width":"stretch","layout":"horizontal","submenu_icon":{"value":"&lt;i className=\"fas fa-caret-down\"&gt;&lt;\/i&gt;","library":"fa-solid"},"toggle":"burger"}'
                data-widget_type="nav-menu.default"
              >
                <Navigation />
              </div>
            </div>
          </div>
          <div
            className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-0cb14b0"
            data-id="0cb14b0"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-8df5139 elementor-align-right elementor-mobile-align-justify elementor-widget elementor-widget-button"
                data-id="8df5139"
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <Link
                      title="Contact Us"
                      href="/contact-us/"
                      className="elementor-button-link elementor-button elementor-size-md"
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-text">
                          Contact us
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section
        className="elementor-section elementor-top-section elementor-element elementor-element-e124f36 elementor-section-height-min-height elementor-section-items-stretch elementor-section-full_width elementor-section-content-middle elementor-section-height-default elementor-sticky--effects elementor-sticky elementor-sticky__spacer"
        data-id="e124f36"
        data-element_type="section"
        data-settings='{"background_background":"classic","sticky":"top","sticky_on":["desktop","tablet","mobile"],"sticky_offset":0,"sticky_effects_offset":0}'
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-4eab1c0"
            data-id="4eab1c0"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-e1e7774 elementor-widget elementor-widget-theme-site-logo elementor-widget-image"
                data-id="e1e7774"
                data-element_type="widget"
                data-widget_type="theme-site-logo.default"
              >
                <div className="elementor-widget-container">
                  <Link href="/" title="IS Auditr Logo">
                    <a>
                      <Image
                           width={100}
                           height={100}
                           loading="lazy"
                           layout="responsive"
                        src="/images/younic-logo-1.svg"
                        className="attachment-full size-full"
                        alt="IS Auditr Logo"
                        loading="lazy"
                      />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-a80e820"
            data-id="a80e820"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-7aff5c2 elementor-nav-menu__align-center elementor-nav-menu--stretch elementor-nav-menu--dropdown-mobile elementor-nav-menu__text-align-aside elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
                data-id="7aff5c2"
                data-element_type="widget"
                data-settings='{"full_width":"stretch","layout":"horizontal","submenu_icon":{"value":"&lt;i className=\"fas fa-caret-down\"&gt;&lt;\/i&gt;","library":"fa-solid"},"toggle":"burger"}'
                data-widget_type="nav-menu.default"
              >
                <div className="elementor-widget-container">
                  <nav
                    className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-underline e--animation-fade"
                  >
                    <ul
                      id="menu-1-7aff5c2"
                      className="elementor-nav-menu"
                    >
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item menu-item-32">
                        <Link href="/" title="Home">
                          <a className="elementor-item elementor-item-active">
                            Home
                          </a>
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-34">
                        <Link href="/services/" title="Services">
                          <a className="elementor-item">Services</a>
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-98">
                        <Link href="/work/" title="Work">
                          <a href="/work/" className="elementor-item">
                            Work
                          </a>
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-97">
                        <Link href="/about-us/" title="About Us">
                          <a className="elementor-item">About Us</a>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                  <div
                    className="elementor-menu-toggle"
                    role="button"
                    tabIndex="0"
                    aria-label="Menu Toggle"
                    aria-expanded="false"
                  >
                    <i
                      aria-hidden="true"
                      role="presentation"
                      className="elementor-menu-toggle__icon--open eicon-menu-bar"
                    ></i>
                    <i
                      aria-hidden="true"
                      role="presentation"
                      className="elementor-menu-toggle__icon--close eicon-close"
                    ></i>
                    <span className="elementor-screen-only">Menu</span>
                  </div>
                  <nav
                    className="elementor-nav-menu--dropdown elementor-nav-menu__container"
                    aria-hidden="true"
                  >
                    <ul
                      id="menu-2-7aff5c2"
                      className="elementor-nav-menu"
                    >
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item menu-item-32">
                        <Link href="/" title="Home">
                          <a className="elementor-item elementor-item-active">
                            Home
                          </a>
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-34">
                        <Link href="/services/" title="Services">
                          <a className="elementor-item">Services</a>
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-98">
                        <Link href="/work/" title="Work">
                          <a className="elementor-item">Work</a>
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-97">
                        <Link href="/about-us/" title="About Us">
                          <a className="elementor-item">About Us</a>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-0cb14b0"
            data-id="0cb14b0"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-8df5139 elementor-align-right elementor-mobile-align-justify elementor-widget elementor-widget-button"
                data-id="8df5139"
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <Link href={"/contact-us"} title="Contact Us">
                      <a
                        className="elementor-button-link elementor-button elementor-size-md"
                        role="button"
                      >
                        <span className="elementor-button-content-wrapper">
                          <span className="elementor-button-text">
                            Contact us
                          </span>
                        </span>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </header>
  );
}
