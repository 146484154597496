import { Suspense } from "react";
import dynamic from "next/dynamic";
import Meta from "@/components/header/meta";
import Header from "@/components/header/header";
import Scripts from "@/components/header/scripts";

const Footer1 = dynamic(() => import("@/components/footer/footer1"), {
  suspense: true,
});

const Footer2 = dynamic(() => import("@/components/footer/footer2"), {
  suspense: true,
});

const Footer3 = dynamic(() => import("@/components/footer/footer3"), {
  suspense: true,
});

export default function Layout({ children }) {
  return (
    <>
      <Meta />
      <div className="bg-pink">
        <Header />

        <main>{children}</main>
        <footer
          data-elementor-type="footer"
          data-elementor-id="364"
          className="elementor elementor-364 elementor-location-footer"
        >
          <Footer1 />
          <Footer2 />
          <Footer3 />
        </footer>
      </div>
      <Suspense fallback={`Loading...`}>
        <Scripts />
      </Suspense>
    </>
  );
}
