import Link from "next/link";
import { useRouter } from "next/router";

export default function Navigation() {
  const router = useRouter();

  return (
    <div className="elementor-widget-container">
      <nav className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-underline e--animation-fade">
        <ul id="menu-1-7aff5c2" className="elementor-nav-menu">
          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item menu-item-32">
            <Link
              href="/"
              title="Home"
              className={
                router.pathname == "/"
                  ? "elementor-item elementor-item-active"
                  : "elementor-item"
              }
            >
              Home
            </Link>
          </li>
          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-34">
            <Link
              href="/services"
              title="Services"
              className={
                router.pathname == "/services"
                  ? "elementor-item elementor-item-active"
                  : "elementor-item"
              }
            >
              Services
            </Link>
          </li>
          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-34">
            <Link
              href="/standards"
              title="Standards"
              className={
                router.pathname == "/standards"
                  ? "elementor-item elementor-item-active"
                  : "elementor-item"
              }
            >
              Standards
            </Link>
          </li>
          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-98">
            <Link
              href="/blog"
              title="Blog"
              className={
                router.pathname == "/blog"
                  ? "elementor-item elementor-item-active"
                  : "elementor-item"
              }
            >
              Blog
            </Link>
          </li>
          {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-98">
            <Link href="/work/" title="Work">
              <a
                className={
                  router.pathname == "/work"
                    ? "elementor-item elementor-item-active"
                    : "elementor-item"
                }
              >
                Work
              </a>
            </Link>
          </li> */}
          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-97">
            <Link
              href="/about-us"
              title="About Us"
              className={
                router.pathname == "/about-us"
                  ? "elementor-item elementor-item-active"
                  : "elementor-item"
              }
            >
              About Us
            </Link>
          </li>
        </ul>
      </nav>
      <div
        className="elementor-menu-toggle"
        role="button"
        tabIndex="0"
        aria-label="Menu Toggle"
        aria-expanded="false"
      >
        <i
          aria-hidden="true"
          role="presentation"
          className="elementor-menu-toggle__icon--open eicon-menu-bar"
        ></i>
        <i
          aria-hidden="true"
          role="presentation"
          className="elementor-menu-toggle__icon--close eicon-close"
        ></i>
        <span className="elementor-screen-only">Menu</span>
      </div>
      <nav
        className="elementor-nav-menu--dropdown elementor-nav-menu__container"
        aria-hidden="true"
      >
        <ul id="menu-2-7aff5c2" className="elementor-nav-menu">
          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item menu-item-32">
            <Link
              href="/"
              title="Home"
              className="elementor-item elementor-item-active"
            >
              Home
            </Link>
          </li>
          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-34">
            <Link href="/services" title="Services" className="elementor-item">
              Services
            </Link>
          </li>
          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-34">
            <Link href="/blog" title="Blog" className="elementor-item">
              Blog
            </Link>
          </li>
          {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-98">
            <Link href="/work" title="Work">
              <a className="elementor-item">Work</a>
            </Link>
          </li> */}
          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-97">
            <Link href="/about-us" title="About Us" className="elementor-item">
              About Us
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}
